.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.toc-list,
.toc-list ol {
  list-style-type: none;
}

.toc-list {
  padding: 0;
  /* margin: 6pt 0 8pt 0; */
}

.toc-list ol {
  padding-inline-start: 6ch;
}

.toc-list > li > a {
  font-weight: bold;
  margin-block-start: 1em;
}

.toc-list li > a {
  text-decoration: none;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: end;
}

.toc-list li > a > .title {
  position: relative;
  overflow: hidden;
}

.toc-list li > a .leaders::after {
  position: absolute;
  padding-inline-start: 0.25ch;
  content: ' . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . '
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'
    '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
  text-align: right;
}

.toc-list li > a > .page {
  min-width: 2ch;
  font-variant-numeric: tabular-nums;
  text-align: right;
}

.table-test {
  width: 100%;
  break-inside: auto;
}
.table-test > tr {
  break-inside: avoid;
  /* break-after: left; */
}
td[rowspan] {
  position: relative;
}

td[rowspan]:before {
  position: absolute;
  content: '';
  top: -1px;
  left: -1px;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
/* textarea {
  white-space: normal;
} */
/* #pdfContent {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 30px 40px;
} */

page {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 94.488188976px;
}
page:not(:last-child) {
  margin-bottom: 40px;
}
img {
  margin: 0 auto;
}
.photo-record:not(:last-child) {
  margin-bottom: 1rem;
}
.elevation-images > p {
  padding-top: 0.8rem;
}

.A4 {
  max-width: 790px;
  min-height: 1120px;
}

.A4-landscape {
  width: 1122px;
  min-height: 793px;
}
