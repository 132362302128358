@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.touch-action-scroll {
  touch-action: none;
  width: calc(100% - 17px);
  overflow: hidden;
}

/* table common */

#table-custom::-webkit-scrollbar {
  display: none !important;
  /* for Chrome, Safari, and Opera */
}

.table-custom tr::after {
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: -16px;
  /* with class ModalCarrot ??*/
}

.custom-buttons {
  text-align: center;
}

.custom-buttons button {
  text-align: center;
  padding: 8px 36px;
  outline: none;
  border-radius: 8px;
  border: none;
  margin-right: 8px;
  background-color: #f0f0f0;
}

.custom-buttons button:hover {
  box-shadow:
    0px 0px 0px #bdbdbd,
    0px 0px 0px #ffffff,
    inset 4px 4px 8px #bdbdbd,
    inset -4px -4px 8px #ffffff;
}

/* narbar */

.before_right_option::before {
  content: " ";
  position: absolute;
  width: 40px;
  height: 50px;
  right: 0;
  background-color: transparent;
}

.jss1 {
  opacity: 1 !important;
}

.jss1:hover {
  opacity: 1 !important;
}

/* animation */

.transition-navbar {
  transition: all 0.4s ease-in-out 0s;
}

/* narbar */

.mark_status_building::before {
  content: " ";
  position: absolute;
  top: 36px;
  width: 44px;
  height: 8px;
  right: 0;
  z-index: 20;
  background-color: transparent;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

/* responsive */

@media (min-width: 1140px) {
  #adminFlexSiderbar {
    width: calc(100% - 224px);
  }
}

@media (min-width: 1500px) {
  #adminFlexSiderbar {
    width: calc(100% - 288px);
  }
}

/* status table */

.status_item:hover .hidden_menu_status {
  display: block;
}

.drag {
  transform: none !important;
}

/* antd custom */

.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition:
    transform 0.8s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-drawer-close {
  margin-right: 0;
}

.ant-collapse-header .ant-collapse-expand-icon {
  margin-top: -3px;
  color: blue;
}

.image-item_overlay {
  height: 0;
  width: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  bottom: 4px;
  background-color: transparent;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #4c4c4c 68.38%);
  opacity: 1;
  transform-origin: bottom right;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s;
}

.box-image-item .image-item_overlay {
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
  height: calc(100% - 8px);
  width: calc(100% - 4px);
  opacity: 1;
  background-image: none;
}

.break-work-custom {
  word-break: break-word;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-container:hover .overlay {
  opacity: 1;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.pentagon {
  clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
}

.top-scrollbars {
  transform: rotateX(180deg);
}

.top-scrollbars > * {
  transform: rotateX(180deg);
}

/* hover-before */

.hover-before:hover::before {
  content: "";
  background: red;
  bottom: 4px;
  left: 0;
  position: absolute;
  width: 40px;
  height: 64px;
}
