.loaderSpinner {
  margin: auto;
  border: 20px solid #fff;
  border-radius: 50%;
  border-top: 20px solid #3e7eff;
  width: 150px;
  height: 150px;
  animation: spinnerLoading 2s linear infinite;
}

@keyframes spinnerLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
