.ant-picker:hover,
.ant-picker-focused {
  border-color: inherit !important;
  border-right-width: 1px !important;
}
.ant-picker-focused {
  border-color: none !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: none !important;
}

.ant-picker-range-separator {
  padding-left: 8px !important;
  padding-right: 12px !important;
  margin-top: -6px !important;
}

.ant-picker {
  border: 1px solid #e4e6e8 !important;
  font-weight: bold !important;
}

.ant-picker-input input {
  font-weight: 600 !important;
  color: #171721 !important;
}
