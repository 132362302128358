.shadow-box-enter {
  opacity: 0;
}
.shadow-box-enter-active {
  opacity: 1;
  transition: 150ms linear;
}
.shadow-box-exit {
  opacity: 1;
}
.shadow-box-exit-active {
  opacity: 0;
  transition: 150ms linear;
}
